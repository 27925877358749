<template>
  <section class="Advantages">
    <div class="Container">
<!--      <h2 class="Title Advantages-Title">Why you should choose us?</h2>-->
      <div class="Advantages-List">
        <div class="Advantages-Item"
             v-for="info in info"
             :key="info.id"
        >
          <img :src="require(`@/assets/images/${info.icon}`)" :alt="info.icon" class="Advantages-ItemIcon">
          <div class="Advantages-ItemInfo">
            <div class="Advantages-ItemNumber">{{ info.number }}</div>
            <p class="Text Advantages-ItemText">{{ info.text }}</p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
// import IOdometer from 'vue-odometer';
// import 'odometer/themes/odometer-theme-default.css';

export default {
  name: "Advantages",
  components: {

   // IOdometer
  },
  data() {
    return {
      num: 2000,
      info: [
        {icon: "experience-icon.svg", number: 2, text: "YEARS of experience"},
        {icon: "result-icon.svg", number: "24/7", text: "YEARS of experience"},
        {icon: "team-icon.svg", number: 30, text: "TEAM members"},
      ],
    }
  },
  // mounted() {
  //   setInterval(function() {
  //     this.number += 1;
  //   }, 500);
  // }
}
</script>

<style scoped lang="scss">
.Advantages {
  padding: 57px 0 53px;

  &-List {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    @media (min-width: $screen-l) {
      flex-direction: row;
    }
  }

  &-Item {
    width: 242px;
    margin-bottom: 40px;
    text-align: center;

    @media (min-width: $screen-l) {
      flex-direction: row;
      margin-bottom: 0;
    }
  }


  &-ItemNumber {
    font-family: 'Lexend', sans-serif;
    font-weight: 800;
    font-size: 80px;
    color: var(--color-text-light);

    @media (min-width: $screen-l) {
      font-size: 100px;
    }
  }

  &-ItemText {
    font-size: 22px;
    text-transform: uppercase;
    color: var(--color-text-main2);
  }
}
</style>