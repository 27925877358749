<template>
  <div>
    <About :aboutInfo="aboutInfo"/>
    <Partners :logos="logos"/>
    <Cards :cardInfo="cardInfo" />
    <TitleRight :title="title"/>
    <Advantages/>
    <Form/>
  </div>
</template>

<script>
import About from "@/components/About";
import Advantages from "@/components/Advantages";
import Partners from "@/components/Partners";
import Form from "@/components/Form";
import Cards from "@/components/Cards";
import TitleRight from "@/components/TitleRight";

export default {
  name: "HomePage",
  components: {
    TitleRight,
    Cards,
    Form,
    Partners,
    Advantages,
    About
  },
  data() {
    return {
      logos: [
        "aliexpress.svg",
        "igg.svg",
        "wish.svg",
        "disney.svg",
        "gett.svg",
        "joom.svg",
        "bayer.svg",
        "omd.svg",
        "bookmade.svg",
      ],
      aboutInfo: {
        title: "Scartesu -",
        text: "is a technological agency of mobile marketing, which helps business to find new customers"
      },
      cardInfo: [
        {
          icon: "acquisition.svg",
          title: "ACQUISITION & MEDIA BUYING",
          disableSub: true,
          text: "Before launching your acquisition strategy, it's important to assess where your organization is within its sector.",
          links: "/ua",
          linkText: "more",
        },
        {
          icon: "website.svg",
          title: "WEBSITE DESIGN & DEVELOPMENT",
          disableSub: true,
          text: "Our Team of web developers take pride in the fact that they design for today and are already tackling the next generation of Web applications.",
          disableClass: true,
        },
        {
          icon: "business.svg",
          title: "BUSINESS INTELLIGENCE CONSULTING",
          disableSub: true,
          text: "Computer software solutions that enable a company or organization to gain insight into its critical operations through reporting applications and analysis tools.",
          disableClass: true,
        },
        {
          icon: "data-analysis.svg",
          title: "DATA ANALYSIS & REPORTING",
          disableSub: true,
          text: "In every data analysis putting the analysis and the results into a comprehensible report is the final, and for some, the biggest hurdle.",
          disableClass: true,
        },
        {
          icon: "multilingual.svg",
          title: "MULTILINGUAL CALL CENTER",
          disableSub: true,
          text: "Our multilingual team can help you answer your prospects’ questions and address their concerns.",
          disableClass: true,
        },
        {
          icon: "customer.svg",
          title: "CUSTOMER SUPPORT & RETENTION",
          disableSub: true,
          text: "Customer retention is the name of the game and the better yours is, the more profitable your brand will be.",
          disableClass: true,
        },
      ],
      title: "Why you should choose us?"
    }
  },
  computed: {

  }
}
</script>

<style scoped lang="scss">

.Cards-ItemLink--disable {
  display: none;
}
</style>